import { calcPrice } from "@tential/ec-gql-schema/utils/functions/price"
import { Article, ItemList, NewsArticle, Product, Store, Thing, WithContext } from "schema-dts"
import { ProductFragmentFragment, StoreFragment } from "~/types/type.generated"
import { MetaCustomType, MetaInfo } from "~/types/meta"
import type { ApiProductSearchResponse_Product } from "~/types/server/api/product"

const useCustomMeta = (): { fetch: (_meta: MetaCustomType) => MetaInfo } => {
  const route = useRoute()

  const fetch = (_meta: MetaCustomType): MetaInfo => {
    _meta.route = route
    return formatMeta(_meta)
  }

  return {
    fetch,
  }
}

// Formatting function
const formatMeta = (_meta: MetaCustomType): MetaInfo => {
  const config = useRuntimeConfig()
  const metaTitle = "TENTIAL[テンシャル] 公式オンラインストア"
  const ogDescription =
    "TENTIAL（テンシャル）の公式オンラインショップ。「よく生きるカラダへ。」をコンセプトに、日常の中でコンディショニングをサポートし、あなたのポテンシャルを輝かせるブランドです。"
  const pageUrl = _meta.url
    ? _meta.url
    : `${config.public.BASE_URL}${_meta.route?.fullPath ? _meta.route?.fullPath : "/"}`
  const nodeEnv = config.public.APP_ENV === "production" ? config.public.APP_ENV : "development"

  const isProduction = !!["production"].includes(nodeEnv)
  const devPrefix = isProduction ? "" : "[dev] "

  const metaScriptInfo: MetaCustomType["script"] = [
    {
      src: `${config.public.BASE_URL}/js/pixel.js`,
      type: "text/javascript",
    },
  ]
  const metaInfo = {
    htmlAttrs: {
      lang: "ja-JP",
    },
    title: `${devPrefix}${_meta.title || metaTitle}`,
    titleTemplate: _meta.title ? _meta.titleTemplate || `%s | ${metaTitle}` : `%s`,
    meta: [
      {
        hid: "charset",
        charset: "utf-8",
      },
      {
        hid: "viewport",
        name: "viewport",
        content: "width=device-width, initial-scale=1, minimum-scale=1",
      },
      // { hid: 'facebook-domain-verification', name: 'facebook-domain-verification', content: "1g4jm3831226uheo1spe5qhfj2uiyv"},
      {
        hid: "description",
        name: "description",
        content: _meta.description || ogDescription,
      },
      {
        hid: "og:type",
        property: "og:type",
        content: _meta.type || "website",
      },
      {
        hid: "og:title",
        property: "og:title",
        content: _meta.title || metaTitle,
      },
      {
        hid: "og:description",
        property: "og:description",
        content: _meta.description || ogDescription,
      },
      { hid: "og:url", property: "og:url", content: pageUrl },
      {
        hid: "og:image",
        property: "og:image",
        content: _meta.image ? _meta.image : `${config.public.BASE_URL}/images/site/ogp.png`,
      },
      {
        hid: "og:site_name",
        property: "og:site_name",
        content: "tential",
      },
      {
        hid: "twitter:card",
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        hid: "twitter:title",
        name: "twitter:title",
        content: _meta.title || metaTitle,
      },
      {
        name: "facebook-domain-verification",
        content: "zr85xfk05hioaec2py30uyvwjjue6e",
      },
      { hid: "twitter:site", name: "twitter:site", content: "@TENTIAL_Inc" },
      {
        hid: "twitter:domain",
        name: "twitter:domain",
        content: "twitter.com/TENTIAL_Inc",
      },
      // 自動翻訳を無効化
      {
        hid: "notranslate:google",
        name: "google",
        content: "notranslate",
      },
      {
        hid: "notranslate:robots",
        name: "robots",
        content: "notranslate",
      },
      {
        hid: "notranslate:googlebot",
        name: "googlebot",
        content: "notranslate",
      },
      {
        hid: "content-language",
        name: "content-language",
        content: "ja-JP",
      },
    ],
    link: [
      {
        rel: "icon",
        type: "image/x-icon",
        href: "/images/meta/favicon-tential.png",
      },
      {
        hid: "canonical",
        rel: "canonical",
        name: "canonical",
        href: _meta.canonical || pageUrl,
      },
    ],
    script: metaScriptInfo,
    __dangerouslyDisableSanitizers: ["script"],
  }

  if (_meta.jsonLDInfo) {
    const ldJson = createLdJson(_meta.jsonLDInfo)
    metaInfo.script.push(ldJson)
  }

  if (_meta.link) metaInfo.link = metaInfo.link.concat(_meta.link)

  if (_meta.script) metaInfo.script = metaInfo.script.concat(_meta.script)
  if (_meta.__dangerouslyDisableSanitizers)
    metaInfo.__dangerouslyDisableSanitizers = metaInfo.__dangerouslyDisableSanitizers.concat(
      _meta.__dangerouslyDisableSanitizers,
    )

  if (isProduction && _meta.robots)
    metaInfo.meta.push({
      hid: "robots",
      name: "robots",
      content: _meta.robots,
    })

  if (!isProduction)
    metaInfo.meta.push({
      hid: "robots",
      name: "robots",
      content: "noindex",
    })

  return metaInfo
}

const createLdJson = (jsonLDInfo: unknown) => {
  return {
    innerHTML: JSON.stringify(jsonLDInfo),
    type: "application/ld+json",
    hid: "json-ld",
  }
}

/**
 * 商品一覧ページ用のjsonLDを作成
 * 共通化を図るためにpage_partsのproductを引数にとる
 * もっと自由にカスタマイズしたい場合は随時変更する
 */
const createProductJsonLDByList = (
  product: ApiProductSearchResponse_Product,
  description?: string,
): WithContext<Product> => {
  return {
    "@context": "https://schema.org",
    "@type": "Product",
    name: product?.material ? `${product.name}/${product.material}` : product?.name,
    image: product?.search_product.lowest_price_sku.main_image,
    description: product?.explanation || description,
    brand: "TENTIAL",
    offers: {
      "@type": "Offer",
      priceCurrency: "JPY",
      price: product.search_product.lowest_price_sku.price
        ? calcPrice(product.search_product.lowest_price_sku).effective_reference_price
        : undefined,
    },
    size: product.size_range,
  }
}

/**
 * 商品ページ用のjsonLDを作成
 * 共通化を図るためにpage_partsのproductを引数にとる
 * もっと自由にカスタマイズしたい場合は随時変更する
 */
const createProductJsonLD = (product: ProductFragmentFragment | undefined, description?: string) => {
  return {
    "@context": "http://schema.org/",
    "@type": "ProductGroup",
    name: product?.name,
    description: product?.explanation || description,
    brand: "TENTIAL",
    hasVariant: product?.skus.map((sku) => ({
      "@type": "Product",
      sku: sku.sku_code,
      name: sku.name,
      image: sku.main_image,
      size: sku.size,
      color: sku.color,
      offers: {
        "@type": "Offer",
        priceCurrency: "JPY",
        price: calcPrice(sku).effective_reference_price || undefined,
        itemCondition: "http://schema.org/NewCondition",
        availability: isSkuOutOfStock(sku) ? "http://schema.org/OutOfStock" : "http://schema.org/InStock",
      },
    })),
  }
}

/**
 * 記事のjsonLDを作成
 * 使いやすいように必要最低限の引数の型をPickしているが必要なプロパティが増えたら追加する
 */
const createArticleJsonLD = (data: Pick<Article, "headline" | "image" | "description">): WithContext<Article> => {
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: data.headline,
    image: data.image,
    description: data.description,
  }
}

/**
 * NewsとJournalのjsonLDを作成
 * 使いやすいように必要最低限の引数の型をPickしているが必要なプロパティが増えたら追加する
 */
const createNewsJsonLD = (data: Pick<NewsArticle, "headline" | "image" | "description">): WithContext<NewsArticle> => {
  return {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: data.headline,
    image: data.image,
    description: data.description,
  }
}

/**
 * 店舗のjsonLDを作成
 */
const createStoreJsonLD = (data: StoreFragment): WithContext<Store> => {
  return {
    "@context": "https://schema.org",
    "@type": "Store",
    name: data.store_name,
    description: data.describe,
    telephone: data.tell_number,
    openingHours: data.hours,
    address: {
      "@type": "PostalAddress",
      addressCountry: "JP",
      addressRegion: data.prefecture,
      postalCode: data.post_code,
      streetAddress: data.address,
    },
    image: data.image?.filter((it): it is string => !!it),
  }
}

/**
 * リストのjsonLDを作成
 * itemsを配列で受け取ることで、商品一覧や記事一覧などのリストを作成できる
 */
const createItemListJsonLD = <T extends Thing>(data: { count: number; items: T[] }): WithContext<ItemList> => {
  return {
    "@context": "https://schema.org",
    "@type": "ItemList",
    numberOfItems: data.count,
    itemListElement: data.items,
  }
}

export {
  useCustomMeta,
  formatMeta,
  createProductJsonLDByList,
  createProductJsonLD,
  createArticleJsonLD,
  createNewsJsonLD,
  createItemListJsonLD,
  createStoreJsonLD,
  createLdJson,
}
